import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { StructSetting } from 'types/common.types';
import { TransactionPromotion } from 'types/promotion.types';
import { ItemReturn } from 'types/return.types';
import { Items } from 'types/transaction.types';
import { currencyFormat, currencyFormatNotFraction } from 'utils';

import Rows from './Rows';

type ListItemProps = {
  item: Items | ItemReturn;
  promotionItems: TransactionPromotion[];
  structSetting?: StructSetting | null;
};

const Divider = () => <Box h='0.4px' w='full' my={2} borderStyle='dashed' border='1px' />;

const ListItem: React.FC<ListItemProps> = ({ item, structSetting }: ListItemProps) => {
  const totalDiscount = Number(item.qty_in_base) * Number(item.price) - item.disc_amount;
  const isPosWms = localStorage.getItem('is_pos_wms');
  const itemName = item.item_name
    .split(',')
    .slice(1)
    .map((word) => word.trim())
    .join(', ');

  return (
    <Box mb={1}>
      {isPosWms === 'pos-wms' ? (
        <Box>
          <Text fontWeight='bold'>
            {item.item_name.includes(',') ? item.item_name.split(',')[0] : item.item_name}
          </Text>
          {structSetting?.show_sku_product && structSetting?.show_variation_product && (
            <Box>
              <Text>{item.item_code}</Text>
              <Text>{itemName}</Text>
            </Box>
          )}
          {structSetting?.show_sku_product && !structSetting?.show_variation_product && (
            <Text>{item.item_code}</Text>
          )}
          {!structSetting?.show_sku_product && structSetting?.show_variation_product && (
            <Text>{itemName}</Text>
          )}
        </Box>
      ) : (
        <Box>
          <Text fontWeight='bold'>{item.item_name}</Text>
          <Text>{item.item_code}</Text>
        </Box>
      )}
      {/* Items Prices */}
      <Rows
        leftText={`${Number(item.qty_in_base)} @ ${
          structSetting?.show_two_digit_fraction
            ? currencyFormat(Number(item.price))
            : currencyFormatNotFraction(Number(item.price))
        }`}
        rightText={
          structSetting?.show_two_digit_fraction
            ? currencyFormat(Number(item.qty_in_base) * Number(item.price))
            : currencyFormatNotFraction(Number(item.qty_in_base) * Number(item.price))
        }
      />

      {/* Discount List */}
      {Number(item.disc_amount) !== 0 && (
        <Rows
          leftText={`Diskon ${
            structSetting?.show_two_digit_fraction
              ? currencyFormat(item.disc_amount)
              : currencyFormatNotFraction(item.disc_amount)
          }`}
          rightText={
            structSetting?.show_two_digit_fraction
              ? currencyFormat(totalDiscount)
              : currencyFormatNotFraction(totalDiscount)
          }
        />
      )}
      {/* Tax List */}
      {structSetting?.show_tax_item && Number(item.tax_amount) !== 0 && (
        <Rows
          leftText={`${structSetting?.tax_label ?? 'Pajak'} ${item.rate}%`}
          rightText={
            structSetting?.show_two_digit_fraction
              ? currencyFormat(item.tax_amount)
              : currencyFormatNotFraction(item.tax_amount)
          }
        />
      )}
      {/* Bundle List */}
      {structSetting?.show_bundle_product &&
        item.is_bundle &&
        item.bundle_child?.map((bundle) => <Text>{`${bundle.qty}x ${bundle.item_name}`}</Text>)}

      {item.notes && item.notes !== '' && <Text>Catatan: {item.notes}</Text>}
      <Divider />
    </Box>
  );
};

export default ListItem;
