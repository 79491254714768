/* eslint-disable @typescript-eslint/no-inferrable-types */
import EscPosEncoder from '@revell29/esc-pos-encoder';
import { loadImage } from 'canvas';
import { stringSplitter } from 'lib/helpers';
import { DetailOrderTransaction, IPosSetting } from 'types';
import { IUserInfo } from 'types/auth.types';
import { StructSetting } from 'types/common.types';
import { TransactionPromotion } from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { ITotalDetail } from 'types/sales.types';
import {
  combineAddress,
  convertToLocalDate,
  currencyFormat,
  currencyFormatNotFraction,
  getPaymentCharge,
  getPaymentsName,
  getTotalItemsHistory,
  maskingDataCustomer,
} from 'utils';

interface RawCommand {
  orderData: DetailOrderTransaction;
  settingPrint: StructSetting | null;
  location: ILocations | undefined | null;
  profile: IUserInfo | null;
  posSetting: IPosSetting;
  totalDetail?: ITotalDetail;
  isReturn?: boolean;
  paperSize: number;
  printCopy: number;
  listTransactionPromotion?: TransactionPromotion[];
}

export async function rawCommand({
  orderData,
  settingPrint,
  location,
  totalDetail,
  isReturn,
  paperSize,
  printCopy,
  listTransactionPromotion,
}: RawCommand): Promise<Uint8Array> {
  const encoder = new EscPosEncoder();
  const paymentCharge = getPaymentCharge(orderData?.payments ?? []);
  const isPosWms = localStorage.getItem('is_pos_wms');

  let result: any = encoder.initialize().setPinterType(Number(paperSize)).align('center');
  if (settingPrint?.show_logo && settingPrint?.logo && settingPrint?.logo.length > 0) {
    const img = await loadImage(settingPrint?.logo as string);
    if (paperSize === 58 || paperSize === 76) {
      result = result.align('center').image(img, 200, 56, 'atkinson');
    } else if (paperSize === 80) {
      result = result.align('center').image(img, 400, 120, 'atkinson');
    }
  }

  result = result.newline().newline().size(0.1);

  if (settingPrint?.show_store_name) {
    result = result.bold(true).line(`${settingPrint?.store_name}`).bold(false);
  }

  if (settingPrint?.show_tagline) {
    result = result.line(`${settingPrint?.tagline}`);
  }
  const lengthSplit = paperSize === 80 ? 45 : 28;

  if (settingPrint?.show_store_information && location && location.address) {
    const arrayAddress = stringSplitter(lengthSplit, location?.address);
    const arrayAdressFull = stringSplitter(lengthSplit, combineAddress(location));
    if (settingPrint?.show_store_address && settingPrint?.show_store_address_full) {
      arrayAddress?.forEach((address: string, _index: number) => {
        result = result.line(`${address}`);
      });
    } else if (settingPrint?.show_store_address) {
      arrayAdressFull?.forEach((address: string, _index: number) => {
        result = result.line(`${address}`);
      });
    }
  }

  result = result
    .newline()
    .newline()
    .align('left')
    .line(`${settingPrint?.transaction_no_label}: ${orderData.salesorder_no}`)
    .line(
      `${settingPrint?.cashier_label}: ${orderData.user_name || orderData.username || orderData.cashier_name}`
    );

  if (settingPrint?.show_salesman && !isReturn) {
    result = result.line(`Salesman: ${orderData.salesmen_name}`);
  }

  result = result.line(`${settingPrint?.date_label}: ${convertToLocalDate(orderData.transaction_date)}`);

  if (settingPrint?.show_customer_information) {
    if (
      settingPrint &&
      settingPrint.show_customer_name &&
      !settingPrint.show_email &&
      !settingPrint.show_phone_number
    ) {
      result = result.line(`${settingPrint.customer_label}: ${orderData.customer_name}`);
    } else {
      if (settingPrint?.show_customer_name) {
        result = result.line(`${settingPrint.customer_label}: ${orderData.customer_name}`);
      }

      if (settingPrint?.show_email && settingPrint?.show_masking_email) {
        result = result.line(
          `Email: ${maskingDataCustomer(orderData.customer?.email || orderData.contact_email || '-')}`
        );
      } else if (settingPrint?.show_email) {
        result = result.line(`Email: ${orderData.customer?.email || orderData.contact_email || '-'}`);
      }

      if (settingPrint?.show_phone_number && settingPrint?.show_masking_phone_number) {
        result = result.line(
          `Telp: ${maskingDataCustomer(orderData.customer?.phone || orderData.contact_phone || '-')}`
        );
      } else if (settingPrint?.show_phone_number) {
        result = result.line(`Telp: ${orderData.customer?.phone || orderData.contact_phone || '-'}`);
      }
    }
  }

  if (isReturn) {
    result = result.align('center').bold(true).line(`RETUR`).bold(false).align('left');
  }

  if (orderData.pos_is_unpaid === true || Number(orderData.pos_is_unpaid) === 1) {
    result = result.align('center').bold(true).line(`BELUM LUNAS`).bold(false).align('left');
  }

  if (orderData.is_canceled || Number(orderData.is_canceled) === 1) {
    result = result.align('center').bold(true).line(`BATAL`).bold(false).align('left');
  }

  result = result.printLine('-');

  // looping items
  for (const item of orderData.items) {
    if (isPosWms === 'pos-wms') {
      result = result
        .bold(true)
        .line(`${item.item_name.includes(',') ? item.item_name.split(',')[0] : item.item_name}`)
        .bold(false);

      if (settingPrint?.show_sku_product && settingPrint?.show_variation_product) {
        if (item.variation_values && item.variation_values.length > 0) {
          result = result.line(`${item.item_code}`).line(
            `${item.item_name
              .split(',')
              .slice(1)
              .map((word) => word.trim())
              .join(', ')}`
          );
        } else {
          result = result.line(item.item_code);
        }
      } else if (settingPrint?.show_sku_product && !settingPrint?.show_variation_product) {
        result = result.line(`${item.item_code}`);
      } else if (!settingPrint?.show_sku_product && settingPrint?.show_variation_product) {
        result = result.line(
          `${item.item_name
            .split(',')
            .slice(1)
            .map((word) => word.trim())
            .join(', ')}`
        );
      }
    } else {
      result = result.bold(true).line(`${item.item_name}`).bold(false).line(`${item.item_code}`);
    }

    result = result.oneLine(
      `${Number(item.qty_in_base)} @ ${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Number(item.price))
          : currencyFormatNotFraction(Number(item.price))
      }`,
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Number(item.qty_in_base) * Number(item.price))
          : currencyFormatNotFraction(Number(item.qty_in_base) * Number(item.price))
      }`
    );

    // Fix discount position for 80mm paper
    if (Number(item.disc_amount) > 0) {
      result = result.oneLine(
        `Diskon ${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(item.disc_amount)
            : currencyFormatNotFraction(item.disc_amount)
        }`,
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(Number(item.qty_in_base) * Number(item.price) - Number(item.disc_amount))
            : currencyFormatNotFraction(
                Number(item.qty_in_base) * Number(item.price) - Number(item.disc_amount)
              )
        }`
      );
    }

    if (settingPrint?.show_tax_item && Number(item.tax_amount) > 0) {
      result = result.oneLine(
        `${settingPrint?.tax_label ?? 'Pajak'} ${Number(item.rate)}%`,
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(Number(item.tax_amount))
            : currencyFormatNotFraction(Number(item.tax_amount))
        }`
      );
    }

    if (settingPrint?.show_bundle_product && item.is_bundle && item.bundle_child) {
      item.bundle_child.map((bundle) => {
        result = result.line(`${bundle.qty}x ${bundle.item_name}`);
      });
    }

    if (item.notes && item.notes !== '') {
      result = result.line(`Catatan: ${item.notes}`);
    }

    result = result.printLine('-');
  }

  result = result.oneLine(
    `${settingPrint?.subtotal_label}`,
    `${
      settingPrint?.show_two_digit_fraction
        ? currencyFormat(Number(orderData.sub_total))
        : currencyFormatNotFraction(Number(orderData.sub_total))
    }`
  );

  if (Number(orderData.total_disc) !== 0) {
    result = result.oneLine(
      'Diskon Barang',
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Number(orderData.total_disc))
          : currencyFormatNotFraction(Number(orderData.total_disc))
      }`
    );
  }

  if (
    Number(orderData.pos_promotion_discount) > 0 &&
    listTransactionPromotion &&
    listTransactionPromotion.length > 0
  ) {
    listTransactionPromotion.map((promo) => {
      result = result.oneLine(
        promo.rules.promotion_name,
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(Number(promo.transactionDiscount))
            : currencyFormatNotFraction(Number(promo.transactionDiscount))
        }`
      );
    });
  }

  if (totalDetail && totalDetail.discountOutlet > 0) {
    result = result.oneLine(
      'Diskon Outlet',
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Number(totalDetail.discountOutlet))
          : currencyFormatNotFraction(Number(totalDetail.discountOutlet))
      }`
    );
  }

  if (totalDetail && totalDetail.discountTrx > 0) {
    result = result.oneLine(
      'Diskon Transaksi',
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Number(totalDetail.discountTrx))
          : currencyFormatNotFraction(Number(totalDetail.discountTrx))
      }`
    );
  }

  if (!totalDetail && (Number(orderData?.service_fee) !== 0 || Number(orderData?.add_fee) !== 0)) {
    result = result.oneLine(
      'Diskon Transaksi',
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(
              Math.abs(
                Number(orderData?.service_fee) > 0 || Number(orderData?.service_fee) !== 0
                  ? Number(orderData?.service_fee)
                  : Number(orderData?.add_disc)
              )
            )
          : currencyFormatNotFraction(
              Math.abs(
                Number(orderData?.service_fee) > 0 || Number(orderData?.service_fee) !== 0
                  ? Number(orderData?.service_fee)
                  : Number(orderData?.add_disc)
              )
            )
      }`
    );
  }

  if (Number(orderData.total_tax ?? 0) > 0 || Number(orderData.total_minus_tax ?? 0) !== 0) {
    result = result.oneLine(
      `${settingPrint?.tax_label}`,
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Number(orderData.total_tax ?? orderData.total_minus_tax))
          : currencyFormatNotFraction(Number(orderData.total_tax ?? orderData.total_minus_tax))
      }`
    );
  }

  if (Number(orderData.shipping_cost) > 0) {
    result = result
      .bold(false)
      .oneLine(
        'Ongkos Kirim',
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(Math.abs(Number(orderData.shipping_cost)))
            : currencyFormatNotFraction(Math.abs(Number(orderData.shipping_cost)))
        }`
      );
  }

  if (Number(orderData.insurance_cost) > 0) {
    result = result
      .bold(false)
      .oneLine(
        'Asuransi Kirim',
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(Math.abs(Number(orderData.insurance_cost)))
            : currencyFormatNotFraction(Math.abs(Number(orderData.insurance_cost)))
        }`
      );
  }

  if (Number(orderData.add_fee) !== 0) {
    result = result.oneLine(
      'Biaya Lainnya',
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Math.abs(Number(orderData.add_fee)))
          : currencyFormatNotFraction(Math.abs(Number(orderData.add_fee)))
      }`
    );
  }

  result = result
    .bold(true)
    .oneLine(
      `${settingPrint?.total_label} (${getTotalItemsHistory(orderData.items)})`,
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(Number(orderData.grand_total))
          : currencyFormatNotFraction(Number(orderData.grand_total))
      }`
    )
    .printLine('-')
    .bold(false);

  // looping payments
  for (const item of orderData.payments) {
    result = result.oneLine(
      getPaymentsName(item),
      `${
        settingPrint?.show_two_digit_fraction
          ? currencyFormat(
              isReturn
                ? Number(item.payment_amount)
                : Number(item.payment_amount) + Number(item.payment_charge)
            )
          : currencyFormatNotFraction(
              isReturn
                ? Number(item.payment_amount)
                : Number(item.payment_amount) + Number(item.payment_charge)
            )
      }`
    );
  }

  result = result.printLine('-');

  if (orderData.pos_is_unpaid === true || Number(orderData.pos_is_unpaid) === 1) {
    result = result
      .bold(true)
      .oneLine(
        'PERLU DIBAYAR',
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(Number(orderData.grand_total) - Number(orderData.payment_amount))
            : currencyFormatNotFraction(Number(orderData.grand_total) - Number(orderData.payment_amount))
        }`
      )
      .printLine('-')
      .bold(false);
  }

  if (Number(paymentCharge.kembalian) > 0) {
    result = result
      .oneLine(
        `${settingPrint?.charge_label}`,
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(Number(paymentCharge.kembalian))
            : currencyFormatNotFraction(Number(paymentCharge.kembalian))
        }`
      )
      .printLine('-')
      .bold(false);
  }

  if (orderData.note && orderData.note !== '') {
    result = result.oneLine('Catatan', orderData.note).printLine('-').bold(false);
  }

  result = result.newline().newline().align('center');

  if (settingPrint?.instagram_footer) {
    result = result.line(`IG ${settingPrint.instagram_footer}`);
  }

  if (settingPrint?.facebook_footer) {
    result = result.line(`FB ${settingPrint.facebook_footer}`);
  }

  if (settingPrint?.twitter_footer) {
    result = result.line(`Twitter ${settingPrint.twitter_footer}`);
  }

  if (settingPrint?.tiktok_footer) {
    result = result.line(`Tiktok ${settingPrint.tiktok_footer}`);
  }

  // const splitLengthFooter = paperSize === 80 ? 42 : 24;
  // const arrayNotes = stringSplitter(splitLengthFooter, settingPrint?.note as string);
  // arrayNotes?.forEach((value: string, _index: number) => {
  //   result = result.text(`${value}`, 45);
  // });

  const splitLengthFooter = paperSize === 80 ? 45 : 28;
  result = result
    .text(String(settingPrint?.note ?? ''), splitLengthFooter)
    .newline()
    .newline()
    .newline()
    .cut()
    .encode();

  const arrayCombine: Array<number> = [];
  for (let i = 0; i < Number(printCopy); i++) {
    result.map((b: number) => arrayCombine.push(b));
  }

  const byteArray = new Uint8Array(arrayCombine);
  return new Uint8Array(byteArray.buffer);
}
