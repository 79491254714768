const config = {
  API_URL: process.env.REACT_APP_API_APP || 'https://core-api-pos-v3.staging-k8s.jubelio.com',
  API_URL_WMS: process.env.REACT_APP_API_WMS || 'https://core-api-pos-wms.staging-k8s.jubelio.com',
  API_STAGING: 'https://core-api-pos-v3.staging-k8s.jubelio.com',
  SOCKETIO_PROD: process.env.REACT_APP_SOCKET_APP || 'wss://core-api-pos-v3.staging-k8s.jubelio.com',
  SOCKETIO_WMS_PROD: process.env.REACT_APP_SOCKET_WMS || 'wss://core-api-pos-wms.staging-k8s.jubelio.com',
  ACL_SALES_ALL: 815,
  ACL_SALES_PRICE_CHECKER: 817,
  ACL_MARKETPLACE_SALES: 300,
  ACL_MARKETPLACE_PRINT_LABEL: 335,
  ACL_AUTH_RETURN: 819,
  ACL_AUTH_CANCEL: 820,
  ACL_AUTH_DP: 821,
  ACL_AUTH_REPRINT: 822,
  ACL_AUTH_RECEIPT_EMAIL: 823,
  ALL_LOCATION: -99,
  SUPPORT_EMAIL: 'support@jubelio.com',
  SUPPORT_EMAIL_WMS: 'support2@jubelio.com',
  SUPPORT_EMAIL_V2: 'support3@jubelio.com',
  WS_CONNECTION: 'ws://localhost:4444',
  SOCKETIO_DEV: 'ws://localhost:3030',
  IMAGE_URL: '',
  SENTRY_DSN: 'https://64b92f9656e54d628fef813b249407a9@o1199134.ingest.sentry.io/6467266',
  POS_APP_PRINT: 'pos://pos.jubelio.com/mobile',
  API_WMS_DEV: 'http://localhost:3031',
  API_WMS_STAGING: 'https://core-api-pos-wms.staging-k8s.jubelio.com',
  QRIS_DYNAMIC: -6,
  QRIS_STATIC: -4,
  MAX_QRIS_AMOUNT: 10000000,
  VOUCHER: -5,
  ACCOUNT_SERVICE_API_URL:
    process.env.REACT_APP_ACCOUNT_SERVICE_API_URL || 'https://account-service.staging-k8s.jubelio.com',
};

config.IMAGE_URL = `${config.API_URL}/systemsetting/logo/1633251040520`;

export const PAYMENT_TYPE = {
  CASH: 1,
  DEBIT: 2,
  CREDIT: 3,
  E_MONEY: 4,
  E_WALLET: 5,
  OTHERS: 6,
  PAY_LATER: 7,
  STORE_CREDIT: 8,
  QRIS: 9,
  VOUCHER: 10,
};

export const PAYMENT = {
  CASH: -1,
  STORE_CREDIT: -3,
};

export const LIST_PAYMENT_TYPE = [
  {
    payment_type: PAYMENT_TYPE.CASH,
    name: 'Tunai',
  },
  {
    payment_type: PAYMENT_TYPE.DEBIT,
    name: 'Debit',
  },
  {
    payment_type: PAYMENT_TYPE.CREDIT,
    name: 'Kredit',
  },
  {
    payment_type: PAYMENT_TYPE.QRIS,
    name: 'QRIS',
  },
];

export const LIST_ALL_PAYMENT_TYPE = [
  {
    payment_type: PAYMENT_TYPE.CASH,
    name: 'Tunai',
  },
  {
    payment_type: PAYMENT_TYPE.DEBIT,
    name: 'Debit',
  },
  {
    payment_type: PAYMENT_TYPE.CREDIT,
    name: 'Kredit',
  },
  {
    payment_type: PAYMENT_TYPE.QRIS,
    name: 'QRIS',
  },
  {
    payment_type: PAYMENT_TYPE.PAY_LATER,
    name: 'Bayar Nanti',
  },
  {
    payment_type: PAYMENT_TYPE.STORE_CREDIT,
    name: 'Store Kredit',
  },
  {
    payment_type: PAYMENT_TYPE.VOUCHER,
    name: 'Voucher',
  },
];

export const TRANSACTION_SALES_TYPE = {
  ALL: 0,
  UNPAID_QRIS: 1,
  NOT_SYNC: 3,
  RETURN: 3,
  UNPAID: 2,
};

export const PROMOTION_TYPES = {
  MinimalQuantity: 1,
  BuyXGetY: 2,
  MinimalTransaction: 3,
  FreeItemTransaction: 4,
  Member: 5,
  Voucher: 6,
};

export const ITEM_ID_TYPES = {
  Product: 'P',
  Category: 'C',
  SKU: 'I',
};

export const ITEM_TYPES = {
  BUY_X: 1,
  GET_Y: 2,
};

export const DISCOUNT_TYPES = {
  Percentage: 1,
  FixedAmount: 2,
};

export const PROMOTION_DISCOUNT_TYPES = {
  Percentage: 'percentage',
  FixedAmount: 'amount',
};

export const PROMOTION_REWARD_TYPES = {
  Discount: 'discount',
  Product: 'product',
};

export const CUSTOMER_TYPE = {
  semua_pelanggan: 1,
  kategori_pelanggan: 2,
  pelanggan: 3,
};

export const CUSTOMER_CATEGORY = {
  walkin_customer: -3,
  new_customer: -2,
};

export const JS_DAYS_MAP: Record<string, string> = {
  d0: 'Sun',
  d1: 'Mon',
  d2: 'Tue',
  d3: 'Wed',
  d4: 'Thu',
  d5: 'Fri',
  d6: 'Sat',
};

export const APPLICABLE_DAYS_MAP: Record<string, number> = {
  Mon: 1,
  Tue: 2,
  Wed: 4,
  Thu: 8,
  Fri: 16,
  Sat: 32,
  Sun: 64,
};

// OpenReplay CompanyID Whitelist
export const COMPANY_ID = [
  2067, // CV Unitech Indonesia
  4095, // BIBIT Bunga
  3703, // Makassar Hobi
  8041, // Wira Internasional
  55547, // Bravani Nusantara Cemerlan (IBacks),
  60377, // OADKIDS
  56267, // Pt Kiip Kiip Indonesia
  62631, // Babygarden
  48404, // House Of Prokleen
  68019, // PT. PANLANDWOO AKSESORIS INDONESIA
  67236, // PT Winod Ritel Indonesia,
  68073, //PT KACA MATA VIRAL
  63886, // Jubelio Corner
  61944, // Gunajaya ATK
  41850, // PT PROTOFLEX OTOPARTS INTERNASIONAL
];

export const CHANNEL = {
  SHOPEE: 64,
  EVERMOS: 131078,
};

export default config;
