import { Box, Button, Checkbox, Textarea, useDisclosure } from '@chakra-ui/react';
import { CardClosure, CardMain } from 'components/card';
import { ModalBanks, ModalCLosureInfo } from 'components/modal';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import ModalDetailPaymentClosure from 'components/modal/ModalDetailPaymentClosure';
import ClosureToPrint from 'components/receipt/ClosureToPrint';
import { TableClosure } from 'components/table';
import { TRANSACTION_SALES_TYPE } from 'constant';
import { alert, app, modal } from 'constant/messages';
import { useGetRegisters, useGetTransaction, useNotification, usePrint } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ErrorHandler } from 'lib/error-handler';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getTakeNotes, resetClosure, savePaymentLists, setItemSold } from 'redux/reducer/closure';
import { setAsCashier, setCurrentClosure } from 'redux/reducer/registers';
import { resetOrder } from 'redux/reducer/sales';
import { setFreshchatCustomConfig } from 'redux/reducer/settings';
import closureRequest from 'services/http/closure.request';
import { db } from 'services/indexdb/connection';
import orders from 'services/indexdb/orders';
import returnOrder from 'services/indexdb/return-order';
import { IClosureStats, IPaymentClosure, IPaymentTrx, IRequestClose } from 'types/closure.types';
import { PickBankNote } from 'types/register.types';
import { formatDate } from 'utils';
import { amountBankNotes } from 'utils/closures';
import { clossureCommand } from 'utils/printer/closure-command';

const ClosurePage = () => {
  const { currentClosure, location, registerInfo } = useAppSelector((state) => state.register);
  const { structSetting, printer } = useAppSelector((state) => state.commons);
  const { profile } = useAppSelector((state) => state.auth);

  const closure = useAppSelector((state) => state.closure);
  const cancelRef = React.useRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notification } = useNotification();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { isOpen, onOpen: onOpenPopup, onClose: onClosePopup } = useDisclosure();
  const { isOpen: isOpenOffline, onOpen: onOpenPopupOffline, onClose: onClosePopupOffline } = useDisclosure();
  const { sendFailTransaction } = useGetTransaction();
  const { getRegisters } = useGetRegisters();

  const componentRef = React.useRef(null);
  const DEFAULT_LOCATION = -1;

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [closureStats, setClosureStats] = React.useState<IClosureStats>();
  const [listPayments, setListPayments] = React.useState<IPaymentClosure[]>();
  const [listPrintPayment, setListPrintPayment] = React.useState<IPaymentClosure[]>();
  const [loadingClose, setLoadingClose] = React.useState<boolean>(false);
  const [disableButton, setDisableButton] = React.useState<boolean>(true);
  const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const [selectedPaymentId, setSelectedPaymentId] = React.useState<number>(-99);
  const [detailPayment, setDetailPayment] = React.useState<IPaymentTrx>();
  const [isOpenBanks, setOpenBanks] = React.useState<boolean>(false);
  const [cashBank, setCashBank] = React.useState<number>(0);
  const [isPrint, setIsPrint] = React.useState<boolean>(false);
  const [isLoadingBtnClose, setLoadingBtnClose] = React.useState<boolean>(false);
  const [isLoadingSyncTransaction, setIsLoadingSyncTransaction] = React.useState<boolean>(false);

  /**
   * Get lists payments of closure by closure id is open
   */
  const getListClosure = async (initCash = 0, closureId = 0, listBanks: PickBankNote[], _cashBank = 0) => {
    try {
      setLoading(true);
      const res = await closureRequest.listPaymentClosure(closureId);
      const totalBankNotes = listBanks?.length > 0 ? amountBankNotes(listBanks) : _cashBank;
      const listPayment = res.listPayments?.map((payment: IPaymentClosure, index: number) => {
        const paymentExpected =
          payment.payment_id === -1
            ? Number(payment.payment_expected) + Number(initCash)
            : Number(payment.payment_expected);

        return {
          ...payment,
          key: payment.payment_id === -1 ? 0 : index + 1,
          payment_expected: paymentExpected,
          payment_counted: payment.payment_id === -1 ? totalBankNotes : paymentExpected,
          different_payment:
            payment.payment_id !== -1
              ? payment.payment_counted - Number(payment.payment_expected)
              : totalBankNotes - paymentExpected,
        };
      });

      setClosureStats({
        totalSales: res.totalSales,
        totalRetur: res.totalRetur,
      });

      listPayment.sort((a, b) => a.key - b.key);
      const joinItems = [...res.totalQtyItemsNonBundle, ...res.totalQtyItemsBundle];
      dispatch(
        setItemSold(
          joinItems.map((val) => ({
            ...val,
            sales_qty: Number(val.sales_qty),
            return_qty: Number(val.return_qty),
          }))
        )
      );
      setListPayments(listPayment);
      setLoading(false);
      setCashBank(_cashBank);
    } catch (err: any) {
      const errorRes = ErrorHandler(err, 'closure-payments');
      notification('', errorRes.message, 'error');
      setLoading(false);
    }
  };

  const fetchUnsyncTransactions = React.useCallback(async () => {
    const [listTransaction, listReturn] = await Promise.all([
      orders.get('', TRANSACTION_SALES_TYPE.NOT_SYNC),
      returnOrder.getUnsync(''),
    ]);
    return {
      totalUnSyncTrx: (listTransaction?.length || 0) + (listReturn?.length || 0),
    };
  }, []);

  /**
   * Show popup detail payment
   * and get list payment of payment closure clicked by user on table
   */
  const getDetailPayment = async (paymentId: number, isDispatch = true) => {
    try {
      setSelectedPaymentId(paymentId);
      const res = await closureRequest.transactionPayment(Number(currentClosure?.closure_id) || 0, paymentId);

      const totalRetur: number = res.data
        .filter((i) => i.transaction_type === 'Retur')
        .reduce((total, item) => total + Number(item.payment_amount), 0);

      const totalSales: number = res.data
        .filter((i) => i.transaction_type === 'Penjualan')
        .reduce((total, item) => total + Number(item.payment_amount), 0);

      if (isDispatch) {
        setDetailPayment({
          listTrx: res.data,
          totalSales,
          totalRetur,
        });
        setOpenDetail(true);
        return;
      }

      return { totalRetur, totalSales };
    } catch (error) {
      const errorRes = ErrorHandler(error, 'detail-payments');
      notification('', errorRes.message, 'error');
      setSelectedPaymentId(-99);
    } finally {
      setSelectedPaymentId(-99);
    }
  };

  /**
   * Count money in cash register of every payment method
   */
  const countPayment = React.useCallback(
    (value: number, paymentId: number) => {
      if (listPayments) {
        const result = listPayments.map((item) => {
          if (item.payment_id === paymentId) {
            if (value) {
              item.different_payment = Number(value) - Number(item.payment_expected);
              item.payment_counted = Number(value);
            } else {
              item.different_payment = Number(0) - Number(item.payment_expected);
              item.payment_counted = Number(value);
            }
          }
          return item;
        });

        setListPayments(result);
      }
    },
    [listPayments]
  );

  const resetState = () => {
    dispatch(resetOrder());
    dispatch(resetClosure());
    dispatch(setAsCashier(false));
    dispatch(setCurrentClosure(null));
    dispatch(setFreshchatCustomConfig(false));
  };

  // Close cash register
  const handleClickClosure = async (_listPrintPayment: IPaymentClosure[]) => {
    try {
      setLoadingClose(true);
      const mappingPayment: IPaymentClosure[] =
        _listPrintPayment?.map((i) => ({
          payment_counted: i.payment_counted,
          payment_expected: i.payment_expected,
          payment_id: i.payment_id,
        })) || [];

      const banksNote = closure.listBanksNote?.map(({ banknote_id, banknote_count }) => ({
        banknote_id,
        banknote_count,
      }));

      const payload: IRequestClose = {
        ...closure.close,
        payment: mappingPayment,
        closure_id: currentClosure?.closure_id || 0,
        register_id: currentClosure?.register_id || 0,
        user_name: currentClosure?.username || '',
        notes: closure.notes,
        banknotes: closure.listBanksNote?.length > 0 ? banksNote : [],
      };

      // check payment list to avoid closing failed
      if (mappingPayment.length === 0) {
        return notification('', app.server_failure_text, 'error', 3000);
      }

      const resClosingRegister = await closureRequest.closeClosure(payload);
      await db.cart.clear();
      // await Promise.all([db.cart.clear(), db.order.clear(), db.return.clear()]);

      getRegisters(location?.location_id || DEFAULT_LOCATION, true);
      if (isPrint) {
        if (currentClosure) {
          dispatch(
            setCurrentClosure({
              ...currentClosure,
              closing_date: resClosingRegister.closing_date,
            })
          );
        }
        return printHandler(listPrintPayment as IPaymentClosure[]);
      }
      resetState();
      setLoadingClose(false);
      navigate('/register');
    } catch (error: any) {
      setLoadingClose(false);
      const errorRes = ErrorHandler(error, 'error-closing-cash-register');
      notification('', errorRes.message, 'error', 3000);
    }
  };

  /**
   * Count money in cash register of every payment method
   * and enable button close if all payment method is counted
   */
  const checkingCountedMoney = React.useCallback(
    (payments: IPaymentClosure[]) => {
      if (payments) {
        const isDisabled = payments.every((val: IPaymentClosure) => val.different_payment === 0);
        if (isDisabled || (closure.notes !== '' && closure.notes !== null)) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      }
    },
    [listPayments, closure.notes]
  );

  const mappingDetailPayment = async () => {
    setLoadingBtnClose(true);
    const { totalUnSyncTrx } = await fetchUnsyncTransactions();
    if (isOnline && totalUnSyncTrx > 0) {
      setLoadingBtnClose(false);
      onOpenPopup();
      return;
    }
    const afterSort = listPayments ? listPayments?.sort((a, b) => Number(a.key) - Number(b.key)) : [];
    dispatch(savePaymentLists([])); // reset listPayments redux

    const updatedPayments = await Promise.all(
      afterSort.map(async (payment) => {
        const res = await getDetailPayment(payment.payment_id, false);
        return {
          ...payment,
          total_sales: res?.totalSales,
          total_retur: res?.totalRetur,
        };
      })
    );

    setLoadingBtnClose(false);
    setListPrintPayment(updatedPayments);
    setOpenConfirmation(!openConfirmation);
  };

  const handleReactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => handleOnBeforeGetContent(),
    onAfterPrint: () => {
      setLoadingClose(false);
      resetState();
      navigate('/register');
    },
  });

  // Dsipatch order detail to redux before dialog print opened to prevent data not updated
  // NOTE: Sometime order detail not updated, so we need to dispatch order detail to redux before print
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        dispatch(savePaymentLists(listPrintPayment as IPaymentClosure[]));
        resolve();
      }, 800);
    });
  }, [listPrintPayment]);

  const printHandler = async (_listPrintPayment: IPaymentClosure[]) => {
    const closureData = {
      listItemSold: closure.listItemSold,
      listPayments: _listPrintPayment,
      listBankNotes: closure.listBanksNote,
      closureId: currentClosure?.closure_id as number,
      openDate: currentClosure?.open_date as Date,
      initCash: Number(currentClosure?.cash_initial),
      notes: closure.notes || '',
      closingDate: currentClosure?.closing_date as Date,
    };

    const data = await clossureCommand({
      closureData,
      settingPrint: structSetting,
      location,
      profile,
      paperSize: Number(printer?.paperSize),
      printCopy: printer?.printCopy,
      registerName: registerInfo?.register_name ?? '',
    });

    // custom hook to print receipt

    usePrint({
      dataPrint: data,
      onErroPrint: () => handleReactToPrint(),
      onIgnorePrint: () => handleReactToPrint(),
      onSuccessPrint: () => {
        resetState();
        navigate('/register');
      },
    });
  };

  const handleSendTransaction = async () => {
    try {
      setIsLoadingSyncTransaction(true);
      await sendFailTransaction();
      onClosePopup();
    } catch (error) {
      notification('', alert.error_send_transaction, 'error');
    } finally {
      setIsLoadingSyncTransaction(false);
    }
  };

  React.useEffect(() => {
    checkingCountedMoney(listPayments || []);
  }, [listPayments, closure.notes]);

  React.useEffect(() => {
    if (!isOnline) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [isOnline]);

  React.useEffect(() => {
    (async () => {
      const { totalUnSyncTrx } = await fetchUnsyncTransactions();
      if (isOnline && totalUnSyncTrx > 0) {
        onClosePopupOffline();
        onOpenPopup();
      } else if (!isOnline) {
        onOpenPopupOffline();
      }
    })();
  }, [isOnline]);

  React.useEffect(() => {
    getListClosure(
      Number(currentClosure?.cash_initial),
      Number(currentClosure?.closure_id),
      closure.listBanksNote,
      cashBank
    );
  }, [cashBank]);

  return (
    <React.Fragment>
      <CardMain>
        <Box
          width={{
            base: 'full',
            sm: 'full',
            md: 'full',
            lg: '100%',
            xl: '100%',
          }}
          px={4}
          py={5}
        >
          <div className='space-y-6'>
            <div className='flex flex-grow-0 space-x-3 overflow-x-auto'>
              <CardClosure
                title='Kas Pembukaan'
                isLoading={true}
                amount={parseInt(currentClosure?.cash_initial || '') || 0}
                subtitle={`Pembukaan kasir ${formatDate(currentClosure?.open_date)}`}
              />
              <CardClosure
                title='Total Penjualan'
                isLoading={true}
                amount={closureStats?.totalSales || 0}
                subtitle={`Pembukaan kasir ${formatDate(currentClosure?.open_date)}`}
              />
              <CardClosure
                title='Total Retur'
                isLoading={true}
                amount={Math.abs(Number(closureStats?.totalRetur)) || 0}
                subtitle={`Pembukaan kasir ${formatDate(currentClosure?.open_date)}`}
              />
            </div>
            <Box
              width={{
                base: 'full',
                sm: 'full',
                md: 'full',
                lg: '100%',
                xl: '100%',
              }}
            >
              <TableClosure
                isLoading={!isLoading}
                disableCash={closure.isBanksNote}
                countPayment={countPayment}
                selectedPaymentId={selectedPaymentId}
                getDetailPayment={getDetailPayment}
                onOpenBank={() => setOpenBanks(true)}
                closureData={{
                  payments: listPayments || [],
                  banksnote: closure.listBanksNote || [],
                  cashBank,
                }}
              />
            </Box>
            <Box
              width={{
                base: 'full',
                sm: 'full',
                md: 'full',
                lg: '100%',
                xl: '100%',
              }}
            >
              <div className='flex items-start space-x-20'>
                <h1 className='pr-20 pt-8'>Keterangan</h1>
                <Textarea
                  placeholder='Tulis keterangan disini'
                  rows={2}
                  mb={3}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    dispatch(getTakeNotes(e.target.value))
                  }
                  value={String(closure.notes)}
                />
              </div>
            </Box>
            <Box
              width={{
                base: 'full',
                sm: 'full',
                md: 'full',
                lg: '100%',
                xl: '100%',
              }}
            >
              <div className='flex flex-col items-end gap-3'>
                <Checkbox
                  colorScheme='red'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsPrint(e.target.checked)}
                >
                  Cetak Struk Penutupan Kasir
                </Checkbox>
                <Button
                  variant='primary'
                  onClick={mappingDetailPayment}
                  isLoading={isLoadingBtnClose}
                  isDisabled={!isOnline || disableButton}
                >
                  Tutup Kasir {disableButton}
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </CardMain>
      <div className='hidden'>
        <ClosureToPrint ref={componentRef} />
      </div>
      <ModalConfirmation
        title={modal.title_tutup_kasir}
        isOpen={openConfirmation}
        subtitle={modal.subtitle_tutup_kasir}
        cancelText={modal.cancel_text}
        okText={modal.title_tutup_kasir}
        loadingSubmit={loadingClose}
        isOnline={isOnline}
        onClose={() => setOpenConfirmation(!openConfirmation)}
        onSubmit={() => {
          dispatch(savePaymentLists(listPrintPayment as IPaymentClosure[]));
          handleClickClosure(listPrintPayment as IPaymentClosure[]);
        }}
      />
      <ModalDetailPaymentClosure
        isOpen={openDetail}
        dataPayment={detailPayment}
        onClose={() => setOpenDetail(!openDetail)}
      />
      <ModalCLosureInfo
        isOpen={isOpen}
        onClose={onClosePopup}
        cancelRef={cancelRef}
        title={alert.title_not_sync_transaction}
        description={alert.not_sync_transaction}
        firstButtonText={'Kehalaman Transaksi'}
        secondButtonText={'Kirim Ulang'}
        onClickFirstButton={() => navigate('/sales/history')}
        onClickSecondButton={handleSendTransaction}
        isLoading={isLoadingSyncTransaction}
      />
      <ModalCLosureInfo
        isOpen={isOpenOffline}
        onClose={onClosePopupOffline}
        cancelRef={cancelRef}
        title={alert.title_offline_closure}
        description={alert.offline_closure}
        firstButtonText={'Ke Halaman Penjualan'}
        secondButtonText={'Refresh'}
        onClickFirstButton={() => navigate('/sales')}
        onClickSecondButton={() => window.location.reload()}
        isLoading={false}
      />
      <ModalBanks
        isOpen={isOpenBanks}
        onClose={() => setOpenBanks(false)}
        onSave={(cash) => setCashBank(cash)}
      />
    </React.Fragment>
  );
};

export default ClosurePage;
